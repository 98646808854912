<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva categoria
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col cols="12" md="8" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar categoria" single-inline hide-details
                        @keydown.enter="searchTransactionCategory" placeholder="Buscar por nombre">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="transactionsCategory" class="elevation-1" fixed-header color="primary"
                :loading="loading" :options.sync="options" :server-items-length="totalTransactionCategory"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }">
                    <!-- <template v-slot:top>
                        <v-toolbar flat>
                            <v-btn small dark color="primary" @click="dialog = true">
                                <v-icon left>fas fa-plus-square</v-icon>Nueva sucursal
                            </v-btn>
                            <v-toolbar-title>Listado de transactionsCategory</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template> -->
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editTransactionCategory(item)" v-if="!item.deleted">fas fa-edit</v-icon>
                        <v-icon small class="mr-2" @click="removeTransactionCategory(item)" v-if="!item.deleted">fas fa-trash-alt</v-icon>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <FormTransactionCategory :transaction-category-id.sync="transactionCategoryId"
        :dialog.sync="dialog" :initialize.sync="initTableTransactionCategory"
        :transaction-type="transactionType" :handleDialog="handleDialog" v-if="dialog"/>
    </v-row>
</template>
<script>
import { getParamsPagination } from '@/utils/pagination';
import Columns from '@/components/shared/Columns.vue';
import TransactionCategoryService from '@/service/transactions/TransactionCategoryService';
import FormTransactionCategory from './FormTransactionCategory.vue';
export default {
    name: 'ListaTransactionCategory',
    components: { Columns, FormTransactionCategory },
    created() {
        this.initTableTransactionCategory();
    },
    data() {
        return {
            transactionType: [
                { text: 'Ingreso', value: 'INCOME' },
                { text: 'Egreso', value: 'EXPENSE' }
            ],
            transactionsCategory: [],
            options: {},
            totalTransactionCategory: 0,
            search: '',
            headers:[
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text: 'ID', align: " d-none", value: 'id', visible: false, sortable: false, groupable: false, show: false },
                { text: 'Nombre categoria', value: 'name', sortable: false, groupable: false, show: true },
                { text: 'Estatus', value: 'transactionTypeNatural', sortable: false, groupable: false, show: true },
            ],
            transactionCategoryId: null,
            loading: false,
            dialog: false,
        }
    },
    methods:{
        searchTransactionCategory(){
            this.options.page = 1;
            this.initTableTransactionCategory( this.options );
        },
        initTableTransactionCategory( options = {} ){
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search });
            TransactionCategoryService.table( params ).then( response => {
                let { rows, totalElements } = response.data;
                this.transactionsCategory = rows;
                this.totalTransactionCategory = totalElements;
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error al obtener los datos', text: err.response.data, showConfirmButton: false, timer: 3500 });
            }).finally(() => {
                this.loading = false;
            })
        },
        editTransactionCategory( item ){
            this.dialog = true;
            this.transactionCategoryId = item.id;
        },
        removeTransactionCategory( item ){
            this.$swal.fire({
                title: '¿Estás seguro?',
                text: '¡No podrás revertir esto!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    TransactionCategoryService.delete(item.id).then( resp => {
                        if( resp.status == 200 ){
                            this.initTableTransactionCategory();
                            this.$swal.fire({ icon: 'success', title: '¡Hecho!', text: 'Se eliminó correctamente', footer: 'Sucursa desactivada', timer: 3500 });
                        }
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', title: 'Oops...', text: 'Algo salió mal!' + err.response.data, footer: 'Intenta de nuevo' });
                    });
                }
            });
        },
        handleDialog(){
            this.dialog = !this.dialog;
            this.transactionCategoryId = null;
        }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableTransactionCategory(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter(h => h.show); },
    }
}
</script>