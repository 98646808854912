<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="720px">
            <v-card class="elevation-2">
                <ValidationObserver ref="obsTransactionCategory" v-slot="{invalid, validated}">
                    <v-form name="form" @submit.prevent="transactionCategoryId ? update() : saveTransactionCategory()">
                        <v-toolbar tile dark color="primary">
                            <v-btn icon dark @click.native="handleDialog">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{  titleDialog }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="danger" text type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large>fas fa-save</v-icon>Guardar categoria</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                 <v-col cols="12" sm="12" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="transactionType" item-text="text" item-value="value"
                                        label="Tipo de transaccion*" v-model="transactionCategory.transactionType" required clearable prepend-icon="fas fa-briefcase"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Nombre de la categoria*" v-model="transactionCategory.name" prepend-icon="fas fa-lock"
                                        :error-messages="errors" :success="valid" required clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import TransactionCategoryService from '@/service/transactions/TransactionCategoryService';
export default {
    name: 'FormTransactionCategory',
    props: {
        transactionCategoryId: { type: Number, default: null },
        dialog: { type: Boolean, default: false },
        transactionType: { type: Array, default: () => [] },
        handleDialog: { type: Function },
        initialize: { type: Function },
    },
    beforeMount() {
        if( this.transactionCategoryId ){
            this.show( this.transactionCategoryId );
        }
    },
    data() {
        return {
            transactionCategory: {
                id:         null,
                name:       '',
                transactionType: 'INCOME',
                deleted:    false
            }
        }
    },
    methods: {
        resetData(){
            this.handleDialog();
            this.transactionCategory = { id: null, name: '', transactionType: 'INCOME', deleted: false };
            this.$refs.obsTransactionCategory.reset();
        },
        saveTransactionCategory(){
            this.$refs.obsTransactionCategory.validate().then( isValid => {
                if( isValid ){
                    TransactionCategoryService.create( this.transactionCategory ).then( rsp => {
                        if (rsp.status === 200 || rsp.status === 201) {
                            this.resetData();
                            this.initialize();
                            this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Datos registrados', text: 'Categoria guardada', showConfirmButton: false, timer: 4000 });
                        }
                    }).catch( err => {
                        console.log(err)
                        this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: err.response.data, showConfirmButton: false, timer: 4000 });
                    })
                }
            }).catch( err  => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: 'No se pudo guardar los datos, ' +err.message, showConfirmButton: false, timer: 4000 });
            })
        },
        show( transactionCategoryId ) {
            TransactionCategoryService.show( transactionCategoryId ).then( rsp => {
                if (rsp.status === 200) {
                    this.transactionCategory = rsp.data;
                }
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: err.response.data, showConfirmButton: false, timer: 4000 });
            })
        },
        update() {
            this.$refs.obsTransactionCategory.validate().then( isValid => {
                if( isValid ){
                    TransactionCategoryService.update( this.transactionCategory ).then( rsp => {
                        if (rsp.status === 200) {
                            this.resetData();
                            this.initialize();
                            this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Actualización', text: 'Categoria actualizada', showConfirmButton: false, timer: 4000 });
                        }
                    }).catch( err => {
                        this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: err.response.data, showConfirmButton: false, timer: 4000 });
                    })
                }
            }).catch( err  => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: 'No se pudo actualizar los datos, ' +err.message, showConfirmButton: false, timer: 4000 });
            })
        }
    },
    watch: {
        transactionCategoryId: function( id ){
            if(id != null && id > 0){
                this.show( id );
            }
        }
    },
    computed: {
        titleDialog(){
            return this.transactionCategoryId ? 'Editar categoria' : 'Nueva categoria';
        }
    }
}
</script>